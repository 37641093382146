import React from "react";
import DefaultLayout from "../layouts";
import { graphql, Link } from "gatsby";
import Seo from "../components/seo";
import * as styles from "./../styles/home.module.scss";
import RoundedButton from "../components/sub-components/roundedButton"
import ReviewsCarousel from "../components/reviewsCarousel";
import { RightArrow } from "../../src/components/icons"
import NovusLogo from "../../static/assets/logos/novus-logo-gray.png"
import ContactLoanOfficer from "../components/contactLoanOfficer";

const IndexPage = ({ data }) => {

  
  const heroBanner = data.directus.heroBanner;
  const infoBanner = data.directus.infoBanner;
  const infoCards = data.directus.infoCards;
  const simpleCta = data.directus.simpleCta;
  const stackedCta = data.directus.stackedCta;
  const loanProcess = data.directus.loanProcess;
  const companyReviews = data.directus.companyReviews;
  const contactFooter = data.directus.contactFooter;

  return (
    <DefaultLayout>
      <Seo 
        title="Novus Home Mortgage" 
        description="Novus Home Mortgage"/>
      <main>
        <div className={styles.headerBannerSection}>
          <div className={styles.gridContainer}>
            <div className={styles.headerBannerWrap}>
              <div className={styles.headline}>
                <span>{heroBanner.heading1}</span>
                <span>{heroBanner.heading2}</span>
                <span>{heroBanner.heading3}</span>
              </div>
              <div className={styles.headerDetails}>
                <div className={styles.tagline}>{heroBanner.TaglineShort}</div>
                <span className={styles.greenLine}></span>
                <div className={styles.headerLongDesc}>
                  {heroBanner.TaglineLong} 
                </div>
                <div className={styles.buttonRow}>
                    {heroBanner.ButtonOneText && heroBanner.ButtonOneDest && ( 
                        <RoundedButton color="greenToBlue" text={heroBanner.ButtonOneText} destination={heroBanner.ButtonOneDest} />
                    )}
                    {heroBanner.ButtonTwoText && heroBanner.ButtonTwoDest && ( 
                        <RoundedButton color="transparentWithGreen" text={heroBanner.ButtonTwoText} destination={heroBanner.ButtonTwoDest } />
                    )} 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.blueBannerSection}>
          <div className={styles.bgNovusLogo}><img src={NovusLogo}/></div>
          <div className={styles.gridContainer}>
            <div className={styles.content}>
              <div className={styles.heading}>{infoBanner.Heading}</div>
              <div className={styles.subtext}>{infoBanner.Subtext}</div>
            </div>
          </div>
        </div>
        <div className={styles.infoCardsSection}>
          <div className={styles.gridContainer}>
            <div className={styles.infoCardsWrap}>
                <div className={styles.cardContainer}>
                    <div className={styles.cardImg}><img src={infoCards?.cardOneImg?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.content}>
                      <div className={styles.cardTitle}>{infoCards.cardOneTitle}</div>
                      <span className={styles.greenLine}></span>
                      <div className={styles.cardDetails}>{infoCards.cardOneDesc}</div>
                    </div>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.cardImg}><img src={infoCards?.cardTwoImg?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.content}>
                      <div className={styles.cardTitle}>{infoCards.cardTwoTitle}</div>
                      <span className={styles.greenLine}></span>
                      <div className={styles.cardDetails}>{infoCards.cardTwoDesc}</div>
                    </div>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.cardImg}><img src={infoCards?.cardThreeImg?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.content}>
                      <div className={styles.cardTitle}>{infoCards.cardThreeTitle}</div>
                      <span className={styles.greenLine}></span>
                      <div className={styles.cardDetails}>{infoCards.cardThreeDesc}</div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div className={styles.simpleCtaSection}>
            <div className={styles.bannerBg}>
              <div className={styles.gridContainer}>
                    <div className={styles.CtaHeadline}>{simpleCta.heading}</div>
                    <div className={styles.CtaButtonWrap}>
                        <Link className={styles.arrowButton} to={simpleCta.buttonDest}  target="_blank">
                            <span>
                                <RightArrow />
                            </span>
                        </Link>
                    </div>
              </div>
            </div>
         
        </div>
        <div className={styles.stackedCtaSection}>
          <div className={styles.background}>
                <div className={styles.gridContainer}>
                    <div className={styles.solidBg}>
                        <img src="/assets/exploreLoanCalculators/green-bg.png" alt="green rectangle" />
                    </div>
                    <div className={styles.stockBg}>
                        <img src="/assets/exploreLoanCalculators/stock-explore-loan-calc.png" alt="loan officer" />
                        <div className={styles.content}>
                            <div className={styles.sectionTitle}>
                                <div>{stackedCta.heading}</div>
                                <span className={styles.greenLine}></span>
                            </div>
                            <div className={styles.sectionCTAContent}>
                                <div className={styles.description} dangerouslySetInnerHTML={{ __html: stackedCta.description }}></div>
                                <div className={styles.buttonRow}>
                                     {stackedCta.btnOneText && stackedCta.btnOneDest && ( 
                                        <RoundedButton color="greenToBlue" text={stackedCta.btnOneText} destination={stackedCta.btnOneDest} />
                                     )}
                                    {stackedCta.btnTwoText && stackedCta.btnTwoDest && ( 
                                        <RoundedButton color="transparentWithGreen" text={stackedCta.btnTwoText} destination={stackedCta.btnTwoDest } />
                                     )} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.loanProcessCardsSection}>
          <div className={styles.gridContainer}>
            <div className={styles.overview}>
                <div className={styles.sectionTitle}>
                    <div>{loanProcess.heading}</div>
                    <span className={styles.greenLine}></span>
                </div>
                <div className={styles.overviewDesc} dangerouslySetInnerHTML={{ __html: loanProcess.description }}></div>
            </div>
            <div className={styles.factsList}>
                <div className={styles.factContainer}>
                    <div className={styles.factIcon}><img src={loanProcess?.cardOneIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.factTitle}>{loanProcess.cardOneHeading}</div>
                    <div className={styles.factDetails}>{loanProcess.cardOneDesc}</div>
                </div>
                <div className={styles.factContainer}>
                    <div className={styles.factIcon}><img src={loanProcess?.cardTwoIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.factTitle}>{loanProcess.cardTwoHeading}</div>
                    <div className={styles.factDetails}>{loanProcess.cardTwoDesc}</div>
                </div>
                <div className={styles.factContainer}>
                    <div className={styles.factIcon}><img src={loanProcess?.cardThreeIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.factTitle}>{loanProcess.cardThreeHeading}</div>
                    <div className={styles.factDetails}>{loanProcess.cardThreeDesc}</div>
                </div>
            </div>
            <div className={styles.buttonsWrap}>
                {loanProcess.btnOneText && loanProcess.btnOneDest &&
                    <RoundedButton color="blueToWhite" text={loanProcess.btnOneText} destination={loanProcess.btnOneDest}/>
                } 
                 {loanProcess.btnTwoText && loanProcess.btnTwoDest &&
                    <RoundedButton color="transparentWithBlue" text={loanProcess.btnTwoText} destination={loanProcess.btnTwoDest} />
                } 

            </div>
          </div>
        </div>
        <div className={styles.reviewsSection}>
          <ReviewsCarousel loanOfficer={companyReviews} version={3} isHomepage={true} />
        </div>
        <div className={styles.articlesSection}>
          <div className={styles.gridContainer}>
          </div>
        </div>
        <div className={styles.contactFooter}>
          <div className={styles.gridContainer}>
              <div className={styles.wrap}>
                  <div className={styles.bg}>
                      <div className={styles.content}>
                          <div className={styles.sectionTitle}>
                              <div>{contactFooter.heading}</div>
                              <span className={styles.greenLine}></span>
                          </div>
                          <div className={styles.sectionCTAContent}>
                              <div>{contactFooter.subheading}</div>
                              <div className={styles.buttonRow}>
                              {contactFooter.btnOneText && contactFooter.btnOneDest &&
                                  <RoundedButton color="greenToBlue" text={contactFooter.btnOneText} destination={contactFooter.btnOneDest} />
                              }{contactFooter.btnTwoText && contactFooter.btnTwoDest &&
                                  <RoundedButton color="transparentWithGreen" text={contactFooter.btnTwoText}  destination={contactFooter.btnTwoDest}  />
                              }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </main>
    </DefaultLayout>
  );
};

export const query = graphql`
  {
    directus {
      heroBanner {
        heading1
        heading2
        heading3
        TaglineLong
        TaglineShort
        ButtonOneDest
        ButtonOneText
        ButtonTwoDest
        ButtonTwoText
      }
      infoBanner {
        Subtext
        Heading
      }
      infoCards {
        cardOneTitle
        cardOneDesc
        cardTwoTitle
        cardTwoDesc
        cardThreeTitle
        cardThreeDesc
        cardOneImg {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        cardTwoImg {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        cardThreeImg {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      simpleCta {
        heading
        buttonDest
      }
      stackedCta {
        heading
        description
        btnOneText
        btnOneDest
        btnTwoText
        btnTwoDest
      }
      loanProcess {
        heading
        description
        cardOneHeading
        cardOneDesc
        cardTwoHeading
        cardTwoDesc
        cardThreeHeading
        cardThreeDesc
        cardOneIcon {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        cardTwoIcon {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        cardThreeIcon {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        btnOneDest
        btnOneText
        btnTwoDest
        btnTwoText
      }
      companyReviews {
        heading
        description
        btnOneText
        btnOneDest
        btnTwoText
        btnTwoDest
        reviews
      }
      contactFooter{
        heading
        subheading
        btnOneText
        btnOneDest
        btnTwoText
        btnTwoDest
      }
    }
  }
`

export default IndexPage;
